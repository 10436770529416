import { useState, useMemo, useEffect } from "react";
import { useRouter } from "next/router";
import { useSelector } from 'react-redux';
import {isUserLoggedIn} from "utils/auth";
import dynamic from "next/dynamic";
import useBreakpoint from "hooks/useBreakpoint";
import { Screen } from "constants/breakpoints";
import { getLoginUrlWithStoreType, isFromNativeApp } from "utils/misc";

const ContactSupportPanel = dynamic(() => import('components/Header/ContactSupportPanel'));
const CloutTraining = dynamic(() => import('components/CloutTraining/_default/CloutTraining'));
const CategoryNavStrip = dynamic(() => import('components/CategoryNav/_default/CategoryNavStrip'));
const PDPNav = dynamic(() => import('components/ProductDetails/PDPNav'));
const ProtectedPage = dynamic(() => import('components/ProtectedPage'));

const Header = dynamic(() => import('components/Header/_default/Header'));
const SidebarNav = dynamic(() => import('components/SidebarNav/_default/SidebarNav'));
const BottomNav = dynamic(() => import('components/SidebarNav/BottomNav'));
const Drawer = dynamic(() => import('components/common/Drawer'));
const PortraitProductVideoPlayer = dynamic(() => import('components/common/PortraitProductVideo'));
const BuyerDsrRequest = dynamic(() => import('components/BuyerDsrRequest'));
const RedAlert = dynamic(() => import('components/RedAlert'));

interface DefaultLayoutProps {
  children: React.ReactElement;
  fullWidth?: boolean;
  showCategoryNav?: boolean;
  pageProps: any;
  showPdpNav?: boolean;
  pageLayoutWrapperClass?: string;
  pageWrapperClass?: string;
  pageContentContainerClass?: string;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, fullWidth, showCategoryNav, pageProps, showPdpNav, pageLayoutWrapperClass, pageWrapperClass, pageContentContainerClass }) => {
  const router = useRouter();
  const isMdScreen = useBreakpoint(Screen.MdScreen);
  const isSmScreen = useBreakpoint(Screen.SmScreen);
  const [isSmScreenSize, setIsSmScreenSize] = useState(false);

  const isSideBarCollapsed = isSmScreenSize
    || router.pathname.startsWith('/order/manage')
    || router.pathname.startsWith('/manage-products')
    || router.pathname.startsWith('/manage-ndr')
    || router.pathname.startsWith('/product/[productId]');

  const [fullWidthNavbarStatus, setFullWidthNavbarStatus] = useState(!isSideBarCollapsed);
  const [isSideNavHover, setSideNavHover] = useState(false);
  const [popupState, setPopupState] = useState<{ [key: string]: boolean }>({});
  const hasNewCloutPosts = useSelector((state: any) => Boolean(state.user?.hasUnreadCloutPost));

  useEffect(() => {
    setIsSmScreenSize(!!isMdScreen);
  }, [isMdScreen]);

  useEffect(() => {
    if (!isUserLoggedIn()) router.push(`${getLoginUrlWithStoreType()}?redirect_uri=${encodeURIComponent(router.asPath)}`);
  }, []);

  useEffect(() => {
    setFullWidthNavbarStatus(!isSideBarCollapsed);
  }, [isSideBarCollapsed]);
  const handleSideNavPopup = (popupId: string) => {
    setPopupState((prevState) => ({ [popupId]: !prevState[popupId] }));
  }

  const sideBarWrapperProps = useMemo(() => {
    if (isSideBarCollapsed) {
      return ({
        onMouseEnter: () => {setFullWidthNavbarStatus(true); setSideNavHover(true)},
        onMouseLeave: () => {setFullWidthNavbarStatus(false); setSideNavHover(false)},
      });
    }
    return {};
  }, [isSideBarCollapsed]);

  return (
    <div className={`page-layout flex flex-col min-h-[101vh] ${pageLayoutWrapperClass || ''}`}>
      {/* Page Header */}
      <div className="h-[60px] smScreen:h-[48px]">
        <Header 
          userCountryPreference={pageProps?.userCountryPreference} 
        />
      </div>

      {/* Page content */}
      <div className="flex">
        {/* Popups */}
        <ContactSupportPanel
          isVisible={popupState['contactSupportPanel']}
          toggle={() => handleSideNavPopup('contactSupportPanel')}
        />
        <CloutTraining
          isVisible={popupState['cloutTraining']} 
          toggle={() => handleSideNavPopup('cloutTraining')} 
        />
        <BuyerDsrRequest
          isVisible={popupState['buyerDsrRequest']} 
          toggle={() => handleSideNavPopup('buyerDsrRequest')} 
        />
        {/* Side Nav */}
        <div className={`sidebar-nav-wrapper relative ${!isSideNavHover && fullWidthNavbarStatus ? `min-w-[225px]`: `min-w-[60px]`} smScreen:hidden`}>
          <div
            className={`flex flex-col fixed overflow-auto bg-theme_1_grey3 justify-between no-scrollbar ${fullWidthNavbarStatus ? `w-[225px]`: `w-[60px]`} top-0 bottom-0 left-0 z-[119] pt-[60px] shadow-[inset_-2px_0px_2px_rgba(0,0,0,0.1)] transition-all duration-200 ease-out delay-0`}
            {...sideBarWrapperProps}
          >
            <SidebarNav
              togglePopup={handleSideNavPopup}
              showFullWidthNavbar={fullWidthNavbarStatus}
              hasNewCloutPosts={hasNewCloutPosts}
              userCountryPreference={pageProps?.userCountryPreference}
            />
          </div>
        </div>
        {/* Content section */}
        <div className={`w-full relative overflow-hidden`}>
          <RedAlert
            message="⚠️ We'll be undergoing temporary maintenance on Friday, 23rd Feb 2024, from 1:00 AM to 2:00 AM. We appreciate your understanding."
            expiryDateTime='2024-02-23 02:00:00'
          />
          <div className={`page-wrapper m-auto ${fullWidth ? 'w-full' : 'max-w-[1170px]'} ${pageWrapperClass || ''}`}>
            <div className={`page-content-container flex flex-col ${pageContentContainerClass || ''}`}>
              {showCategoryNav && !isSmScreen && (
                <div
                  id="category-nav-strip-container"
                  className={`fixed z-20 top-[60px] right-0 ${fullWidthNavbarStatus ? `left-[225px]`: `left-[60px]`} bg-theme_1_white shadow-[0px_4px_10px_rgba(0,0,0,0.1)] border-t border-solid border-theme_1_border`}>
                  <CategoryNavStrip />
                </div>
              )}
              {showPdpNav && !isSmScreen && (
                <div
                  id="pdp-nav-strip-container"
                  className={`fixed z-20 top-[60px] right-0 ${fullWidthNavbarStatus ? `left-[225px]`: `left-[60px]`} bg-theme_1_white shadow-[0px_4px_10px_rgba(0,0,0,0.1)] border-t border-solid border-theme_1_border`}
                >
                  <PDPNav pageProps={pageProps} />
                </div>
              )}
              <ProtectedPage pageProps={pageProps}>
                {children}
              </ProtectedPage>
            </div>
          </div>
        </div>
      </div>

      {/* Product Details Drawer for small screens  */}
      {isSmScreen && <Drawer />}

      {/* Bottom Nav for small screen */}
      {!isFromNativeApp() && isSmScreen && <BottomNav userCountryPreference={pageProps?.userCountryPreference} />}

      {/* Portrait Product Video Player for small screen */}
      {isSmScreen && <PortraitProductVideoPlayer />}
    </div>
  );
};

export default DefaultLayout;
